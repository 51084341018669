.btnPerso {
  background-color: #383b87;
  color: white;
  border-color: #383b87;
  margin-left: 2rem;
  margin-right: 2rem;
  --bs-btn-bg: #383b87;
  --bs-btn-border-color: #383b87;
  --bs-btn-hover-bg: #383b87;
  --bs-btn-hover-border-color: #383b87;
  --bs-btn-active-bg: #383b87;
  --bs-btn-active-border-color: #383b87;
  --bs-btn-disabled-bg: #383b87;
  --bs-btn-disabled-border-color: #383b87;
  --bs-link-color: #383b87;
  --bs-link-hover-color: #383b87;
}

.btn:hover {
  background-color: #383b8700;
  color: #383b87;
  border-color: #383b87;
}

.bgCard {
  background-color: #e2e2e213;
}

.btnDirection {
  background-color: #212529;
  color: white;
  border-color: #212529;
  margin-left: 2rem;
  margin-right: 2rem;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
  --bs-link-color: #212529;
  --bs-link-hover-color: #212529;
}

.btnDirection:hover {
  background-color: #ffffff00;
  color: #212529;
  border-color: #212529;
}

/* swiper */
/* #app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  display: none;
}

.nav-pills .nav-link.active {
  background-color: #383b87;
}

.nav-pills {
  --bs-link-color: black;
  --bs-link-hover-color: #383b87;
}

.backdrop-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #21252933;
  backdrop-filter: blur(3px);
  height: 100%;
  width: 100%;
  z-index: 9000;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

/* spinner custom */
.loader {
  width: 96px;
  box-sizing: content-box;
  height: 48px;
  background: #fff;
  border-color: #000000;
  border-style: solid;
  border-width: 2px 2px 50px 2px;
  border-radius: 100%;
  position: relative;
  animation: 3s yinYang linear infinite;
}
.loader:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  background: #fff;
  border: 18px solid #000000;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  box-sizing: content-box;
}
.loader:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background: #000000;
  border: 18px solid #fff;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  box-sizing: content-box;
}
@keyframes yinYang {
  100% {
    transform: rotate(360deg);
  }
}
